@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.message_from_rikky {

  user-select: none;
  bottom: $apx-60;
  left: $apx-22;
  font-family: Montserrat;
  padding: $apx-21 $apx-54 $apx-21 $apx-23 !important;
  z-index: 3;
  border-radius: $apx-18;
  box-shadow: 0px (-$apx-1) $apx-38 0px rgba(1, 142, 161, 0.40);


  &__image {

    $size: $apx-161;
    width: $size !important;
    min-width: $size !important;
    height: $size !important;
    border-radius: $apx-23 !important;
    border: $apx-4 solid #154356;
    background-image: url('./media/rikky.jpg') !important;

  }

  &__content {

    padding-top: $apx-2;

    &__title {
      
      font-size: $apx-21;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: $apx-14;
      color: #DFCA62;

    }

    &__text {

      color: #41C5CD;
      text-shadow: 0px $apx-2 $apx-4 rgba(0, 0, 0, 0.07);
      font-size: $apx-18;
      font-weight: 500;
      line-height: 140%;

    }

    &__subtitle {

      margin-top: $apx-18;
      
      color: #72FFE6;
      font-size: $apx-17;
      font-weight: 700;
      line-height: 100%;

      span {

        display: block;
        margin-left: $apx-8;
        color: #00D6AF;
      }

      svg {

        width: $apx-22;
        min-width: $apx-22;
        height: $apx-25;
        margin-top: -$apx-5;
        margin-right: $apx-14;
        animation: moveUpDown 2s infinite linear;

      }
      
    }


  }

  &__close {

    $size: $apx-17;
    width: $size;
    min-width: $size;
    height: $size;
    right: $apx-17;
    top: $apx-13;
    transition: 0.3;
    
    &:hover {

      transform: scale(1.15);
      filter: brightness(1.2);

      & ~ .close_variants { opacity: 1 !important; }

    }
    
  }

  .tariff__respect {

    margin: unset;
    padding: unset;

  }

  .close_variants {

    width: 16vw;
    height: 7vw;
    top: -$apx-100;
    right: -$apx-260;
    color: #699EAF;
    opacity: 0;
    transition: 0.25s;

    &:hover { opacity: 1; }

    &__var {

      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      font-size: $apx-16;
      font-weight: 500;
      line-height: 100%;
      transition: 0.3s;
      padding: $apx-8 $apx-10;
      border: $apx-2 solid #134657;
      border-radius: $apx-10;
      background: #1e2c39f6;

      &--1 {
        top: $apx-28;
        left: -$apx-68;
      }

      &--2 {
        bottom: -$apx-4;
        left: $apx-88;
      }

      &:hover {

        transform: scale(1.05);
        filter: brightness(1.2);

        & ~ .message_from_rikky__close {

          transform: scale(1.05);
          // filter: brightness(1.2);

        }
  
      }

    }
  
    &__line {
  
      width: $apx-24;
      height: $apx-2;
      background: #699EAF;

      &--1 {

        transform: rotate(90deg);
        bottom: $apx-54;
        left: $apx-9;

      }

      &--2 {

        
        top: $apx-121;
        left: $apx-56;
        
      }
  
    }

  }

}

@media screen and (max-width: 1600px) {
  
  .message_from_rikky { bottom: $apx-80 !important; }

}

@media screen and (max-width: 1230px) {
  
  .message_from_rikky { bottom: $apx-90 !important; }

}

@media screen and (max-width: 1050px) {
  
  .message_from_rikky { bottom: $apx-115 !important; }

}

@include mobile {

  .message_from_rikky { display: none !important; }
  
}