@import "../../../../styles/_apx.scss";
@import "../../../../styles/_mixins.scss";

.container {

  position: absolute;
  bottom: 73px;
  left: center;
  width: $apx-398;
  display: flex;
  flex-direction: column;
  gap: $apx-13;
  align-items: center;

  .icon {

    position: absolute;
    width: 23px;
    height: 12px;
    bottom: -11px;

    path { transition: 0.4s }

  }

  .iconLight {

    path { fill: #fff }

  }

}

@include mobile {

  .container {

    width: 300px;

  }

}