@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.vcf {

  &__container {

    width: 100%;
    padding-top: $apx-10;

    .loading { 
      
      position: relative;
      margin-bottom: $apx-20;
    
    }

    p.error { font-size: $apx-18 !important; line-height: 124%; }

  }

  &__title { 
    
    margin-bottom: $apx-12;
    font-size: $apx-22;
    line-height: 100%;
  
  }

  &__subtitle { 
    
    margin-bottom: $apx-18; 
    font-size: $apx-16;
    line-height: 130%;
    opacity: 0.6;
    color: var(--color-text) !important;

    a { 
      
      color: var(--color-text) !important; 
    
    }
  
  }

  &__code {

    max-width: $apx-260;
    margin: auto;

    .textfield { 
      
      width: unset !important;
      margin-bottom: unset !important; 

      input {

        margin: unset !important;
        padding: unset !important;
        width: $apx-50 !important;
        height: $apx-60 !important;
        text-align: center;
        font-size: $apx-30 !important;
        line-height: 100%;
        
      }
    
    }

  }

  &__resend {

    margin-top: $apx-14;

  }

  &__ifnothingcode {

    padding: $apx-13 $apx-10 $apx-20;
    background-color: rgba(255,255,255,0.06);
    background-color: rgba(0,0,0,0.16);
    border-radius: $apx-10;

  }

}

@include mobile {

  .vcf {

    &__container {
  
      padding-top: 10px;
  
      .loading { 
        
        margin-bottom: 20px;
      
      }
  
      p.error { font-size: 18px !important; line-height: 124%; }
  
    }
  
    &__title { 
      
      margin-bottom: 12px;
      font-size: 22px;
      line-height: 100%;
    
    }
  
    &__subtitle { 
      
      margin-bottom: 18px; 
      font-size: 16px;
      line-height: 130%;
    
    }
  
    &__code {
  
      max-width: 260px;
  
      .textfield { 
        
        input {
  
          width: 50px !important;
          height: 60px !important;
          font-size: 30px !important;
          
        }
      
      }
  
    }
  
    &__resend {
  
      margin-top: 14px;
  
    }
  
    &__ifnothingcode {
  
      padding: 13px 10px 20px;
      border-radius: 10px;
  
    }
  
  }

}