@import "../../../../../../styles/_apx.scss";
@import "../../../../../../styles/_mixins.scss";

.remove_relation {

  top: $apx-16;
  right: $apx-24;
  font-family: 'Roboto Mono';
  font-size: $apx-20;
  line-height: 100%;
  font-weight: 400;
  color: #D18783;
  opacity: 0.5;
  transition: 0.3s;

  &:hover { opacity: 1; }

}

.reltabsel {

  max-height: $apx-650;
  padding-bottom: $apx-6;

  &__title {

    font-family: 'Roboto Mono';
    font-size: $apx-26;
    line-height: 100%;
    font-weight: 500;
    padding-left: $apx-6;
    margin-bottom: $apx-30;

  }

  .table__form__liner_x { margin-bottom: $apx-20; }

  &__list {

    max-height: $apx-405;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-columns: 1fr 1fr;
    row-gap: $apx-26;
    column-gap: $apx-14;
    width: 100%;
    // overflow-x: hidden;
    overflow-x: auto;
    overflow-y: auto;
    padding-bottom: $apx-10;

    &__el {

      font-family: 'Roboto Mono';
      font-size: $apx-21;
      line-height: $apx-24;

      svg {

        position: relative;
        top: ($apx-1 / 2);
        width: $apx-30;
        height: $apx-24;
        margin-right: $apx-14;

      }

    }

  }

}

@include mobile {

  .remove_relation {

    top: 16px;
    right: 24px;
    font-size: 20px;
    opacity: 1;
  
  }
  
  .reltabsel {
  
    max-height: 650px;
    padding-bottom: 6px;
  
    &__title {
  
      font-size: 26px;
      padding-left: 6px;
      margin-bottom: 30px;
  
    }
  
    .table__form__liner_x { margin-bottom: 20px; }
  
    &__list {
  
      max-height: 405px;
      row-gap: 26px;
      column-gap: 14px;
      padding-bottom: 10px;
  
      &__el {
  
        font-size: 21px;
        line-height: 24px;
  
        svg {
  
          top: 0.5px;
          width: 30px;
          height: 24px;
          margin-right: 14px;
  
        }
  
      }
  
    }
  
  }

}