@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.options {

  $radius: $apx-10;

  top: $apx-48;
  z-index: 2;
  position: absolute;
  right: $apx-0;
  padding: $apx-0;
  background: #15232f;
  border-radius: $radius;
  transition: 0.3s;
  transform-origin: top center;
  transform: scale(0);
  overflow: hidden;
  &.opened { transform: scale(1); }

  hr {
    margin: $apx-18 0 $apx-16;
    border-color: #0c0f15;
  }

  &__element {

    font-size: $apx-18;
    padding: $apx-11 $apx-20 $apx-11;
    border: $apx-1 solid #0c0f15;
    white-space: nowrap;

    // &:not(:last-child) { border-bottom: $apx-1 solid #0c0f15; }
    &:not(:last-child) { border-bottom: unset; }
    &:first-child { border-radius: $radius $radius 0 0; }
    &:last-child { border-radius: 0 0 $radius $radius; }

    &:hover {

      background: #1d3244;
      border: $apx-1 solid #2e4064;
      
    }

  }
 
}

@include mobile {

  .options {

    $radius: 10px;

    top: 32px;
    border-radius: $radius;
  
    hr {
      margin: 18px 0 16px;
    }
  
    &__element {
  
      font-size: 18px;
      padding: 11px 20px 11px;
      border: 1px solid #0c0f15;
      
      &:first-child { border-radius: $radius $radius 0 0; }
      &:last-child { border-radius: 0 0 $radius $radius; }
  
      &:hover {
  
        border: 1px solid #2e4064;
        
      }
  
    }
   
  }

}