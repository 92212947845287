@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.loading {
  
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: grid;
  place-content: center;

  &__text {

    font-size: $apx-20;
    line-height: $apx-32;
    margin-top: 20px;
    color: #80C5FF;
    animation: fadeInOut 1.7s infinite;

  }

  &__loader {

    margin: auto;
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;

    &::before,
    &::after {

      border-radius: 50%;
      content: "";
      display: block;
      height: 20px;
      width: 20px;

    }

    &::before {

      animation: ball1 1s infinite;
      background-color: #386992;
      box-shadow: 30px 0 0 #80C5FF;
      margin-bottom: 10px;

    }

    &::after {

      animation: ball2 1s infinite;
      background-color: #80C5FF;
      box-shadow: 30px 0 0 #386992;

    }

  }

  &--light {

    .loading__text { color: #E0DDDA; }
  
    .loading__loader::before {
  
      background-color: #E87A3D !important;
      box-shadow: 30px 0 0 #e8793d68 !important;
  
    }
  
    .loading__loader::after {
  
      background-color: #E87A3D !important;
      box-shadow: 30px 0 0 #e8793d68 !important;
  
    }  

  }

}

@keyframes rotate {

  0% { transform: rotate(0deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(1.2) }
  100% { transform: rotate(720deg) scale(0.8) }

}

@keyframes fadeInOut {

  0% { opacity: 0.6; }
  50% { opacity: 0.9; }
  100% { opacity: 0.6; }

}

@keyframes ball1 {

  0% {
    box-shadow: 30px 0 0 #80C5FF;
  }

  50% {
    box-shadow: 0 0 0 #80C5FF;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #80C5FF;
    margin-bottom: 10px;
  }

}

@keyframes ball2 {

  0% {
    box-shadow: 30px 0 0 #386992;
  }

  50% {
    box-shadow: 0 0 0 #386992;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #386992;
    margin-top: 0;
  }

}





@include mobile {
  
  .loading {
  
    &__text {
  
      font-size: 16px;
      line-height: 24px;
  
    }
  
  }  

}