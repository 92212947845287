@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.wrapper {

  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  left: $apx-20;
  z-index: 1000;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-bottom: 20vh;
  padding-bottom: 7vh;

}

.container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: $apx-10;
  width: $apx-70;
  padding: $apx-15;
  border-radius: $apx-14;
  background-color: #223648e0;
  box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);  
  transition: 0.4s;
  perspective: $apx-700;

  &:hover {
    background-color: #223648;
  }

  .line {

    width: $apx-40;
    height: $apx-1;
    background-color: #324351;
    transition: 0.4s;

  }

  .icon {

    cursor: pointer;
    transition: 0.3s;
    position: relative;

    &:hover {

      transform: scale(1.115);

    }

    &.groups {

      top: $apx-1;
      width: $apx-38;
      height: $apx-38;

    }

    &.table {

      width: $apx-28;
      height: $apx-28;
      margin: $apx-5 0;

    }

    &.text {

      width: $apx-24;
      height: $apx-24;

    }

  }

  .tool {

    position: absolute;
    left: $apx-85;
    top: 50%;
    transform-origin: left;
    transition: 0.4s;
    transform: rotateY(77deg) translateY(-50%);
    opacity: 0;

  }

  .shown {

    opacity: 1;
    transform: rotateY(0deg) translateY(-50%);

  }

}

.containerLight {

  background-color: #ffffffec;
  box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

  &:hover { background-color: #fff; }

  .line {

    background-color: #7197BF;

  }

}

@include mobile {

  .wrapper {

    left: 8px;
  
  }
  
  .container {
  
    gap: 4px;
    width: 40px;
    padding: 11px;
    border-radius: 8px;
    box-shadow: -4px 5px 18px rgba(0, 0, 0, 0.103);
    perspective: 700px;
  
    .line {
  
      width: 40px;
      height: 1px;
  
    }
  
    .icon {

      &.groups {
  
        top: 0px;
        width: 18px;
        height: 18px;
  
      }
  
      &.table {
  
        width: 28px;
        height: 28px;
        margin: 5px 0;
  
      }
  
      &.text {
  
        width: 24px;
        height: 24px;
  
      }
  
    }
  
    .tool {
  
      left: 48px;
  
    }
  
  }

}