@import "../../../../../../styles/_apx.scss";
@import "../../../../../../styles/_mixins.scss";

.container {

  width: 100%;
  padding: $apx-19 $apx-22 $apx-20;
  border-radius: $apx-14;
  display: flex;
  flex-direction: column;
  gap: $apx-14;
  background-color: #243442;
  box-shadow: 0 0.052083vw 1.197909vw 0.156249vw rgba(0, 0, 0, 0.1490196078);
  transition: 0.4s;

  .title {

    font-weight: 400;
    text-align: left;
    transition: 0.4s;

    .main {

      font-size: $apx-21;
      line-height: $apx-21;
      color: #B2DCFF;

    }

    .help {

      margin-top: $apx-6;
      margin-bottom: $apx-8;
      font-size: $apx-17;
      line-height: $apx-19;
      color: #486D8B;

    }

  }

}

.containerLight {

  background-color: #fff;
  box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

  .title .main, 
  .title .help { 
    
    color: #3e5767; 
  
  }

}

@include mobile {

  .container {

    padding: 19px 22px 20px;
    border-radius: 14px;
    gap: 14px;
  
    .title {
  
      .main {
  
        font-size: 18px;
        line-height: 100%;
  
      }
  
      .help {
  
        margin-top: 10px;
        margin-left: -3px;
        margin-bottom: 4px;
        font-size: 17px;
        line-height: 19px;
  
      }
  
    }
  
  }

}