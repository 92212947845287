@import "../../../../../styles/_apx.scss";
@import "../../../../../styles/_mixins.scss";

.container {

  width: 100%;
  padding: $apx-19 $apx-21 $apx-4;
  border-radius: $apx-14;
  background-color: #243442;
  text-align: left;
  box-shadow: 0 0.052083vw 1.197909vw 0.156249vw rgba(0, 0, 0, 0.1490196078);
  transition: 0.4s;

  div {
    
    font-weight: 400;
    font-size: $apx-21;
    line-height: $apx-26;
    color: #B2DCFF;

  }

  input {

    height: unset !important;
    margin: 0 !important;
    margin-top: $apx-20 !important;
    padding: $apx-11 $apx-16 !important;
    border: $apx-1 solid #43586B !important;
    border-radius: $apx-8 !important;
    font-weight: 400 !important;
    font-size: $apx-19 !important;
    line-height: $apx-26;
    text-align: left !important;
    color: rgb(140, 180, 215) !important;

    ::placeholder {

      font-weight: 400 !important;
      font-size: $apx-19 !important;
      line-height: $apx-26;

    }

  }

}

.containerLight {

  background-color: #fff;
  box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

}

@include mobile {

  .container {

    padding: 16px 14px 0px;
    border-radius: 14px;
    box-shadow: 0 0.052083vw 1.197909vw 0.156249vw rgba(0, 0, 0, 0.1490196078);
    transition: 0.4s;
  
    div {
      
      font-size: 17px;
      line-height: 100%;
  
    }
  
    input {
  
      margin-top: 20px !important;
      padding: 9px 16px 11px !important;
      border: 1px solid #43586B !important;
      border-radius: 8px !important;

      font-size: 16px !important;
      line-height: 100%;
  
      ::placeholder {
  
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 100%;
  
      }
  
    }
  
  }
  
  .containerLight {
  
    box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);
  
  }

}