@import "../../../../styles/_apx.scss";
@import "../../../../styles/_mixins.scss";

.container {

  position: relative;
  width: $apx-375;
  padding: $apx-19 $apx-20 $apx-30;
  border-radius: $apx-14;
  display: flex;
  flex-direction: column;
  background-color: #243442ea;
  box-shadow: 0 0.052083vw 1.197909vw 0.156249vw rgba(0, 0, 0, 0.1490196078);
  transition: 0.3s;
  font-family: 'Roboto Mono';
  user-select: none;
  &:hover { background-color: #243442f8; }

  .title {

    margin-bottom: $apx-46;
    font-weight: 500;
    font-size: $apx-19;
    line-height: $apx-26;
    letter-spacing: 0.015vw;
    color: #B2DCFF;
    text-align: center;
    transition: 0.4s;

  }

  .line {

    position: absolute;
    left: 0;
    top: $apx-63;
    width: 100%;
    height: $apx-1;
    background-color: #324351;
    transition: 0.4s;

  }

  .rows {

    display: flex;
    flex-direction: column;
    gap: $apx-9;
    max-height: $apx-188;
    padding-right: $apx-10;
    overflow-y: auto;

    .row {

      display: flex;
      align-items: center;

      .checkbox { margin: 0; }

      .colorRow {

        gap: 0.72vw;
        
        div {

          width: 1.48vw !important;
          height: 1.48vw !important;

        }

        p {

          font-size: $apx-16 !important;

        }
      
      }

    }

    &::-webkit-scrollbar {

      width: $apx-5;
      border-radius: $apx-3;
      background-color: #293D4E;

    }
    
    &::-webkit-scrollbar-thumb {

      width: $apx-5;
      border-radius: $apx-3;
      background-color: #527DA2;

    }

  }

}

.containerLight {

  background-color: #ffffffda;
  &:hover { background-color: #fffffff5; }
  box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

  .title { color: #3e5767 }

  .line { background-color: #f0f0f0; }

  .rows {

    &::-webkit-scrollbar {

      background-color: transparent;

    }

  }

}

@include mobile {

  .container {

    width: 375px;
    padding: 19px 20px 30px;
    border-radius: 14px;
    transform: scale(0.6);
    transform-origin: left;
  
    .title {
  
      margin-bottom: 46px;
      font-size: 19px;
      line-height: 26px;
      letter-spacing: 0.015vw;
  
    }
  
    .line {
  
      top: 63px;
      height: 1px;
  
    }
  
    .rows {
  
      gap: 19px;
      max-height: 188px;
      padding-right: 10px;
  
      .row {
  
        .colorRow {
  
          gap: 12px;
          
          div {
  
            $size: 27px;
            min-width: $size !important;
            width: $size !important;
            height: $size !important;
  
          }
  
          p {
  
            font-size: 15px !important;
  
          }
        
        }
  
      }
  
      &::-webkit-scrollbar {
  
        width: 5px;
        border-radius: 3px;
  
      }
      
      &::-webkit-scrollbar-thumb {
  
        width: 5px;
        border-radius: 3px;
  
      }
  
    }
  
  }

}