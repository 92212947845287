@import "_apx.scss";
@import "_mixins.scss";

.absolute { position: absolute; }
.relative { position: relative; }
.fixed { position: fixed; }
.events-none { pointer-events: none; }
.pointer { cursor: pointer; }
.hidden { display: none; }
.flex { display: flex; }
.desktop-flex { display: flex; }
.flex-col { flex-direction: column; }
.flex-1 { flex: 1 1; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.items-center { align-items: center; }
.items-bottom { align-items: flex-end; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.font-semibold { font-weight: 600; }
.p-0 { padding: 0 !important; }
.py-10 { padding: $apx-10 0; }
.overflow-x { overflow-x: scroll; overflow-y: hidden; }
.rotate-180 { transform: rotate(180deg); }
.underline { text-decoration: underline; }

.mobile, .mobile-flex { display: none; }

@include mobile {

  .desktop, .desktop-flex { display: none; }

  .mobile { display: block; }
  .mobile-flex { display: flex; }

}