@import "../../../../../styles/_apx.scss";
@import "../../../../../styles/_mixins.scss";

.dtform {

  display: grid;
  grid-template-columns: 27% 28% 29.5% auto;
  padding: 0 $apx-6 $apx-13;

  &__col:last-child {

    padding-right: $apx-4;

  }

  &__group {

    font-family: 'Montserrat';
    margin-bottom: $apx-18;
    font-size: $apx-19;
    line-height: normal;
    font-weight: 600;
    color: #B2DCFF;

  }

  &__type {

    font-family: 'Roboto Mono';
    font-size: $apx-18;
    line-height: normal;
    font-weight: 400;
    color: #8CB4D7;

    &:hover { color: #c1e2ff; }

    & + .dtform__group { margin-top: $apx-38; }
    & + .dtform__type { margin-top: $apx-15; }

  }

}

@include mobile {

  .dtform {

    padding: 0 6px 13px;
  
    &__col:last-child {
  
      padding-right: 4px;
  
    }
  
    &__group {
  
      margin-bottom: 18px;
      font-size: 19px;
  
    }
  
    &__type {
  
      font-size: 18px;
  
      & + .dtform__group { margin-top: 38px; }
      & + .dtform__type { margin-top: 15px; }
  
    }
  
  }

}