@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.constructor { 
  
  .button_big_add {

    position: fixed;
    right: $apx-20;
    bottom: $apx-20;
    z-index: 2000;
    padding: $apx-12 $apx-14;
    padding-left: $apx-30;
    background: #1e2c39;
    border-radius: $apx-100;
    
  }

  .fullscreen-tip { 

    color: rgba(179,220,255,.05);
    top: 48vh;
    width: 100vw;
    position: fixed;
    height: 100%;

  }

  &__space {

    transform-origin: top left;
    transition: 0.3s;

    &--hide-relations {
      .table__row__btn-remove + div { opacity: 0; }
    }

  }

}

.react-transform-wrapper {
  width: 100vw !important;
  height: 100vh !important;
  min-height: 100vh !important;
  overflow: unset !important;
}

.react-transform-component {

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  // background: rgba(255, 255, 0, 0.057); //debug area

  // &::after{ //debug center
  //   position: absolute;
  //   content: "";
  //   display: block;
  //   width: 50px;
  //   height: 50px;
  //   background: red;
  //   top:0;bottom:0;left:0;right:0;
  //   margin: auto;
  //   opacity: 0.3;
    
  // }
  
}

.constructor__space {

  transform: scale(0.2);
  transform-origin: center;

  width: 3000px !important;
  height: 2000px !important;

  // background: rgba(255, 255, 0, 0.057); //debug area

}


@include mobile {
  
  .constructor { 
  
    .button_big_add {
  
      left: 2px;
      right: 2px;
      margin: auto;
      bottom: 6px;
      padding: 12px 14px;
      padding-left: 30px;
      border-radius: 100px;
      
    }

  }

}