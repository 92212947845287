@import "../../../styles/_apx.scss";

.container {
  
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: $apx-56;
  
  &--constructor { min-height: 100vh; }

}