@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.textarea {

  width: 100%;
  max-width: $apx-382;
  margin-bottom: $apx-22;

  &__title {

    user-select: none;
    margin-bottom: $apx-8;
    padding-left: $apx-3;
    font-size: $apx-16;
    font-weight: 300;
    color: #769CBB;

  }

  textarea {

    width: 100%;
    min-height: $apx-42;
    line-height: $apx-32;
    overflow: hidden;
    padding: $apx-9 $apx-9 $apx-9 $apx-14 !important;
    font-family: Roboto;
    font-size: $apx-19;
    color: #B2DCFF;
    background: unset;
    border: $apx-1 solid #43586B;
    border-radius: $apx-6;

    &:hover,
    &:focus { outline: unset; }

  }

}

@include mobile {

  .textarea {

    max-width: 382px;
    margin-bottom: 22px;
  
    &__title {
  
      margin-bottom: 8px;
      padding-left: 3px;
      font-size: 16px;
  
    }
  
    textarea {
  
      min-height: 42px;
      line-height: 110%;
      padding: 9px 9px 9px 14px !important;
      font-size: 19px;
      border: 1px solid #43586B;
      border-radius: 6px;
  
    }
  
  }

}