@import "../../../../../../styles/_apx.scss";
@import "../../../../../../styles/_mixins.scss";

.container {

  position: relative;
  width: 100%;
  padding: $apx-14 $apx-20 $apx-20;
  border-radius: $apx-14;
  display: flex;
  flex-direction: column;
  background-color: #243442;
  text-align: left;
  box-shadow: 0 0.052083vw 1.197909vw 0.156249vw rgba(0, 0, 0, 0.1490196078);
  transition: 0.4s;

  .header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $apx-31;

    .title {

      font-weight: 700;
      font-size: $apx-21;
      line-height: $apx-22;
      color: #B2DCFF;
      text-align: center;
      transition: 0.4s;
  
    }

    .cancel, .confirm {

      width: $apx-27;
      height: $apx-27;

    }

    .cancel {

      transform: rotate(45deg);

      path { 
        
        stroke: #F07B73;
        fill: #F07B73;
      
      }

    }

  }

  .line {

    position: absolute;
    left: 0;
    top: $apx-53;
    width: 100%;
    height: $apx-1;
    background-color: #324351;
    transition: 0.4s;

  }

  .input {

    margin-bottom: $apx-28;

    div { 
      
      font-size: $apx-20 !important; 
      color: #769CBB;
    
    }

    input {

      height: unset !important;
      margin: 0 !important;
      padding: $apx-12 $apx-17 $apx-16;
      border: $apx-1 solid #43586B;
      border-radius: $apx-8 !important;
      font-weight: 400 !important;
      font-size: $apx-19 !important;
      line-height: $apx-20;
      text-align: left;
      color: #B2DCFF !important;
      transition: 0.4s;
  
      ::placeholder {
  
        font-weight: 400 !important;
        font-size: $apx-17 !important;
        line-height: $apx-20;
        color: #769CBB;
  
      }

    }

  }

  .colorBlock {

    margin-bottom: $apx-17;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {

      margin: 0;
      font-weight: 400;
      font-size: $apx-19;
      line-height: $apx-28;
      padding-bottom: $apx-3;
      color: #769CBB;
      transition: 0.4s;

    }

  }

  .color {

    width: $apx-27;
    height: $apx-27;
    border: $apx-2 solid transparent;
    border-radius: $apx-4;
    background-clip: content-box;

  }

  .spectrum {

    margin-top: $apx-12;
    display: flex;
    justify-content: space-between;

  }

  .textfield {

    &__title {

      font-size: $apx-20 !important;

    }

  }

}

.containerLight {

  background-color: #fff;
  box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

  .header .title { color: #3e5767 }

  .line { background-color: #a8a8a8 }

  .input input { color: rgb(57, 57, 57) !important }

  .colorBlock .text { color: #3e5767 }

}

@include mobile {

  .container {

    padding: 14px 20px 20px;
    border-radius: 14px;
  
    .header {
  
      margin-bottom: 31px;
  
      .title {
  
        font-size: 16px;
        line-height: 22px;
    
      }
  
      .cancel, .confirm {
  
        width: 27px;
        height: 27px;
  
      }
  
    }
  
    .line {
  
      top: 53px;
      height: 1px;
  
    }
  
    .input {
  
      margin-bottom: 18px;
  
      div { font-size: 16px !important; }
  
      input {
  
        padding: 10px 12px 12px;
        border: 1px solid #43586B;
        border-radius: 8px !important;
        font-size: 17px !important;
        line-height: 20px;
    
        ::placeholder {
    
          font-size: 17px !important;
          line-height: 20px;
    
        }
  
      }
  
    }
  
    .colorBlock {
  
      margin-bottom: 17px;
  
      .text {
  
        font-size: 15px;
        line-height: 28px;
        padding-bottom: 3px;
  
      }
  
    }
  
    .color {
  
      width: 27px;
      height: 27px;
      border: 2px solid transparent;
      border-radius: 4px;
  
    }
  
    .spectrum {
  
      margin-top: 12px;
  
    }
  
    .textfield {
  
      &__title {
  
        font-size: 20px !important;
  
      }
  
    }
  
  }

}