@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.payment-result {

  height: $apx-545;
  max-width: $apx-545;
  padding: $apx-13 $apx-42 $apx-35 $apx-46;
  border: $apx-3 solid #67CD59;

  svg {
  
    width: $apx-160;
    height: $apx-130;
    position: relative;
    top: $apx-15;
    left: $apx-150;
  
  }

  button {
  
    background: linear-gradient(0deg, #38874E, #38874E), #3084A8;
    border: $apx-1 solid #46BA5F;
    border-radius: $apx-6;
    padding: $apx-15;
    text-align: center;
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: $apx-20;
    line-height: $apx-26;
    color: #FBFEFD;
    cursor: pointer;

    &:hover {

      background: linear-gradient(0deg, #38874E, #38874E), #3084A8;
      border: $apx-1 solid #46BA5F;
    
    }
  
  }

  &__title {
  
    font-weight: 500;
    color: #6CD75B;
    font-size: $apx-40;
    line-height: $apx-46;
    padding-bottom: $apx-6;
    text-align: center;
  
  }

  &__subtitle {
  
    opacity: 0.25;
    font-weight: 500;
    color: #B3DCFF;
    font-size: $apx-21;
    line-height: $apx-30;
    text-align: center;
    top: -$apx-20;
    left: -$apx-5;
    position: relative;
    white-space: nowrap;
  
  }

  &__thanking {
  
    font-weight: 400;
    color: #B3DCFF;
    font-size: $apx-26;
    line-height: $apx-36;
    margin-top: $apx-50;
    margin-bottom: $apx-35;
    text-align: center;
  
  }

  &--fail {

    border-color: #ff0e0e;

    .payment-result__title { color: #ff3939; }  

    .button--blue {

      background: linear-gradient(0deg, #873838, #873838), #a83030 !important;
      border-color: #ff6666 !important;

    }

  }

}

@include mobile {

  .payment-result {

    margin-top: 80px;
    margin-bottom: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;

    height: unset;
    max-width: 96%;
    padding: 13px 16px 21px 16px;
    border: 3px solid #67CD59;
  
    svg {
    
      width: 120px;
      height: 100px;
      margin: auto;
      left: 0;
      right: 0;
      top: 15px;
      margin: unset;
    
    }
  
    button {
    
      border: 1px solid #46BA5F;
      border-radius: 6px;
      padding: 15px;
      font-size: 18px;
      line-height: 26px;
    
    }
  
    &__title {
    
      font-size: 23px;
      line-height: 32px;
      padding-bottom: 6px;
      margin-bottom: unset;

      & + .payment-result__thanking {
        
        margin-top: 12px !important;
        margin-bottom: 21px;

      }
    
    }
  
    &__subtitle {
    
      font-size: 11px;
      line-height: 18px;
      top: 0;
      left: 0;
      margin-top: unset;
    
    }
  
    &__thanking {
    
      font-size: 18px;
      margin: 42px 0 34px;
    
    }

    &--fail {

      border-color: #ff0e0e;

      .payment-result__title { color: #ff3939; }  

      .button--blue {

        background: linear-gradient(0deg, #873838, #873838), #a83030 !important;
        border-color: #ff6666 !important;

      }

    }
  
  }

}