@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.sign {

  margin: $apx-20 unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: #1E2C39;

  &__content{
     
    width: 100%;
    padding: 20px;
    border-radius: $apx-27;
    max-width: $apx-422;
    position: relative;
    top: -$apx-30;
    background: #243442;    
         
    .logo{

      background-image: url(./logo-big.svg);
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      max-width: $apx-220;    
      height: $apx-140;
      margin: $apx-25 auto $apx-25;
      
    }

    &-reg {

      top: -$apx-44;
               
      .logo{

        max-width: $apx-170;    
        height: $apx-80;
        margin: $apx-5 auto $apx-5;

      }

    }

    hr { 
      min-width: calc( 100% + 40px );
      margin-left: -20px;
    }

    &__content {

      padding: $apx-17 $apx-20;
      border-bottom: $apx-1 solid #2C3C4B;

    }

  }

  &--up .logo { margin: $apx-23 auto $apx-23; }

  .checkbox {

    cursor: pointer;
    margin: $apx-20 0;
  
    &__anchor {
  
      width: 100%;
      height: 100%;
      min-width: $apx-24;
      min-height: $apx-24;
      max-width: $apx-24;
      max-height: $apx-24;
      background-color: #28343f;
      border: 2px solid #688fa1;
      border-radius: $apx-3;
      
      svg { 
  
        min-width: $apx-24;
        min-height: $apx-24;
        max-width: $apx-24;
        max-height: $apx-24;
        position: relative;
        transform: scale(0); 
        opacity: 0;
        transition: 0.3s;
  
      }
  
    }
  
    &__title {
  
      padding-top: $apx-3;
      margin-left: $apx-11;
      font-size: $apx-15;
      line-height: $apx-19;
      color: var(--color-text);
      user-select: none;
  
      a {
        color: #44afda;
        text-decoration: underline;
      }
      
    }
  
    &--active .checkbox__anchor { 
      
      background-color: #1e2c39;
      border-color: #799cbb;
  
      svg { 
  
        transform: scale(1) !important; 
        opacity: 1 !important;
        path { stroke: #a2bdd5; }
  
      }
    
    }
  
  }

}

@include mobile {

  .sign {

    margin: 20px unset;
  
    &__content{
       
      border-radius: 27px;
      width: 90%;
      max-width: 320px;
      top: -16px;  
           
      .logo {
  
        max-width: 214px;    
        height: 140px;
        margin: 25px auto 25px;
        
      }
  
      &-reg {
  
        top: 54px;
        margin-bottom: 90px;
                 
        .logo {
  
          max-width: 170px;    
          height: 80px;
          margin: 5px auto 5px;
  
        }
  
      }
  
      &__content {
  
        padding: 17px 20px;
        border-bottom: 1px solid #2C3C4B;
  
      }
  
    }
  
    &--up .logo { margin: 23px auto 23px; }
  
    .checkbox {
  
      margin: 20px 0;
    
      &__anchor {
    
        $size: 24px;
        min-width: $size;
        min-height: $size;
        max-width: $size;
        max-height: $size;
        border-radius: 3px;
        
        svg { 
    
          min-width: $size;
          min-height: $size;
          max-width: $size;
          max-height: $size;
    
        }
    
      }
    
      &__title {
    
        padding-top: 3px;
        margin-left: 11px;
        font-size: 15px;
        line-height: 19px;
        
      }
    
    }
  
  }

}