@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.lang {

  & + a { margin-left: $apx-30; }

  left: $apx-20;
  cursor: pointer;    
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: $apx-15;
  line-height: 100%;
  color: #8be2dd;

  &__text { padding-left: $apx-8; }

  &__arrow { 

    $size: $apx-10;
    width: $size;
    height: $size;

    margin-left: $apx-8; 
    transform: rotate(0deg);
    transition: 0.3s;

    path { fill: #8be2dd; }

  }

  &__flag svg {

    position: relative;
    top: $apx-1;
    $size: $apx-34;
    width: $size;
    height: $size;

  }

  &--opened .lang__arrow { transform: rotate(180deg); }
  
  .options {

    transform-origin: top right;
    top: $apx-38;
    // border: $apx-1 solid #2e4064;

    background: linear-gradient(328deg, #243945db 17.6%, #18272de8 92.77%);   

    &__element {

      padding: $apx-8 $apx-12 $apx-8; 
      border-color: #475966;
      transition: 0.3s;

      &:hover {

        background: linear-gradient(90.16deg, #245264db 17.6%, #213945db 92.77%);
        // border: 1px solid #435b66;
        
      }

    }

    .lang__item {

      svg { margin-right: $apx-10; }
      color: #fff;

    }

  }

}

@include mobile {

  .lang {

    margin-left: 12px;
    top: 0px;
    left: unset;

    & + a { margin-left: unset; }

    font-size: 12px;

    &__flag { 
      
      position: relative; 
      top: 2px; 

      svg {

        top: -1px;
        $size: 31px;
        width: $size;
        height: $size;
    
      }
    
    }

    &__text { padding-left: 6px; }

    &__arrow { 

      $size: 9px;
      width: $size;
      height: $size;

      top: 0px; 
      margin-left: 6px; 

    }

    &--opened .lang__arrow { transform: rotate(180deg); }
    
    .options {

      top: 38px;
        
      &__element { padding: 8px 12px 8px; }

      .lang__item {
        
        font-size: 13px;

        svg { 
          
          $size: 30px;
          width: $size;
          height: $size;
          margin-right: 10px; 
        
        }
      
      }

    }

  }

}