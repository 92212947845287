@import "../../../../../../styles/_apx.scss";
@import "../../../../../../styles/_mixins.scss";

.relcolsel {

  .reltabsel__list {

    padding-left: $apx-6;
    
    &__el {

      margin-left: $apx-8;
      margin-bottom: $apx-36;
      font-size: $apx-23;
      line-height: 100%;

      svg {

        top: $apx-1;
        width: $apx-33;
        height: $apx-27;
        margin-right: $apx-18;

      }

      .relcolsel__choose_other_table {

        top: $apx-2;
        margin-left: $apx-14;
        font-size: $apx-20;
        line-height: $apx-24;
        color: #5F809C;

      }

    }
  
  }

}

@include mobile {

  .relcolsel {

    .reltabsel__list {
  
      padding-left: 6px;
      
      &__el {
  
        margin-left: 8px;
        margin-bottom: 36px;
        font-size: 23px;
  
        svg {
  
          top: 1px;
          width: 33px;
          height: 27px;
          margin-right: 18px;
  
        }
  
        .relcolsel__choose_other_table {
  
          top: 2px;
          margin-left: 14px;
          font-size: 20px;
          line-height: 24px;
  
        }
  
      }
    
    }
  
  }

}