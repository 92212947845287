@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.button{

  width: 100%;
  // max-width: $apx-382;
  min-height: $apx-50;
  border-radius: $apx-6;
  padding-bottom: $apx-1;
  font-weight: 400;
  font-size: $apx-18;
  color: #FBFEFD;
  text-align: center;
  font-family: Rubik !important;
  cursor: pointer;
  user-select: none;

  &:hover, &:focus { outline: none; }

  & + .button { margin-left: $apx-14; }

  &--blue {

    background: #274964;
    border: $apx-1 solid #3A5E87;
    &:hover{ background: #285376; }

  }

  &--green {

    background: #0BA264;
    border: $apx-1 solid #09945B;
    &:hover{ background: #18af70; }

  }

  &--red {

    background: #9F2A38;
    border: $apx-1 solid #9F2A38;
    &:hover{ background: #cc3345; }

  }

  &--gray {

    background: #1e2932;
    border: $apx-1 solid #1a262f;
    &:hover{ background: #161f27; }

  }

}

@include mobile {

  .button{

    min-height: 50px;
    border-radius: 6px;
    padding-bottom: 1px;
    font-size: 18px;
  
    & + .button { margin-left: 14px; }

  }

}