@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.textfield {

  width: 100%;
  max-width: $apx-382;
  margin-bottom: $apx-18;

  &__title {

    user-select: none;
    margin-bottom: $apx-13;
    padding-left: $apx-3;
    font-size: $apx-16;
    font-weight: 400;
    color: #769CBB;

  }

  input {

    width: 100%;
    height: $apx-44;
    padding: 0 $apx-16 $apx-3;
    font-size: $apx-19;
    color: #B2DCFF;
    border: $apx-1 solid #43586B;
    background: unset;
    border-radius: $apx-8;

    &:hover,
    &:focus { outline: unset; }

  }

}

.flex > .textfield + .textfield { margin-left: $apx-16; }

.modal .textfield { max-width: unset; }

input[type="number"] {

  -moz-appearance: textfield;
  appearance: none;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.searchIcon {

  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: $apx-1;
  right: $apx-7;
  margin: auto;
  $size: $apx-32;
  width: $size;
  height: $size;

}

@include mobile {

  .textfield {

    max-width: 382px;
    margin-bottom: 18px;
  
    &__title {
  
      margin-bottom: 13px;
      padding-left: 3px;
      font-size: 16px;
      font-weight: 400;
  
    }
  
    input {
  
      height: 44px;
      padding: 0 16px 3px;
      font-size: 19px;
      border: 1px solid #43586B;
      border-radius: 8px;
  
    }
  
  }
  
  .flex > .textfield + .textfield { margin-left: 16px; }
  
  .modal .textfield { max-width: unset; }
  
  input[type="number"] {
  
    -moz-appearance: textfield;
    appearance: none;
  
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  
  .searchIcon {
  
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 1px;
    right: 7px;
    margin: auto;
    $size: 32px;
    width: $size;
    height: $size;
  
  }

}