@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.tariffbar {

  width: 100%;
  max-width: $apx-851;
  margin: auto;
  user-select: none;

  &__content {

    position: relative;
    top: -1px;
    font-size: $apx-18;
    font-weight: 500;
    color: #2FBCB4;
    white-space: nowrap;


    &--tariffName span { padding-right: $apx-8; }

    &--remaining span.desktop { padding: 0 $apx-8; }

  }

  &__progress {

    width: 100%;
    height: 1vw;
    height: 0.9vw;
    background: #243544;
    border-radius: 100px;
    padding: $apx-5;
    margin: 0 $apx-20;

    &__bar {

      height: 100%;
      background: #2FBCB4;
      border-radius: 100px;

    }

  }

}

@include mobile {

  .tariffbar {

    opacity: 0.57;
    position: relative;
    top: 0.4px;
    padding: 0 12px;
    max-width: unset;
  
    &__content {
  
      top: 0px;
      font-size: 12px;

      &--tariffName {

        b { 
          
          max-width: 70px;
          overflow: hidden;
          // text-overflow: ellipsis;
        
        }

      }
  
    }
  
    &__progress {
  
      height: 1vw;
      height: 0.9vw;
      padding: 0;
      margin: 0 6px;
  
      &__bar {
  
        height: 100%;
        background: #2FBCB4;
        border-radius: 100px;
  
      }
  
    }
  
  }

}