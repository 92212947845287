@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.schemes_container {

  width: 100%;
  max-width: $apx-898;
  margin: 0 auto;
  padding-top: $apx-40;

}

.schemes {

  &__head {

    perspective: $apx-1000;
    padding-right: $apx-6;

    &__title {

      font-size: $apx-48;
      font-weight: 600;
      color: rgba(179, 220, 255, 0.26);

    }

    .button_big_add { top: $apx-3; }

  }

  &__list {

    margin-top: $apx-42;
    margin-bottom: $apx-42;
    border-radius: $apx-27;
    overflow: hidden;

  }

}

.scheme_add {

  pointer-events: none;
  position: absolute;
  top: 0;
  right: -$apx-365;
  width: $apx-342;
  padding: $apx-27;
  border-radius: $apx-27;
  border: $apx-1 solid #455F74;
  background: #243442;
  transition: 0.3s;
  opacity: 0;
  transform-origin: left;
  transform: rotateY(65deg);

  &__title {

    user-select: none;
    font-size: $apx-33;
    font-weight: 700;
    margin-bottom: $apx-27;
    color: rgba(179, 220, 255, 0.45);

  }

  &__icon {

    position: absolute;
    top: $apx-24;
    right: $apx-15;
    width: $apx-59;
    height: $apx-50;

  }

}

.schemes--add {

  .scheme_add {
    
    pointer-events: all;
    opacity: 1;
    transform: rotateY(0deg);

  }

  .button_big_add svg { transform: rotate(135deg) scale(1.3); }

  .textarea textarea { min-height: $apx-80; }

}

@include mobile {

  .schemes_container {

    max-width: 92%;
    padding-top: 40px;
    padding-bottom: 100px;
  
  }
  
  .schemes {
  
    &__head {
  
      perspective: 1000px;
      padding-right: 6px;
      z-index: 4;
  
      &__title { font-size: 32px; }
  
      .button_big_add { top: 3px; }
  
    }
  
    &__list {
  
      margin-top: 18px;
      margin-bottom: 42px;
      border-radius: 22px;
  
    }
  
  }
  
  .scheme_add {

    top: 56px;
    right: 0;
    width: 342px;
    padding: 27px;
    border-radius: 27px;
    border: 1px solid #455F74;
    transform-origin: top;
    transform: rotateX(65deg);
  
    &__title {
  
      font-size: 33px;
      margin-bottom: 27px;
  
    }
  
    &__icon {
  
      top: 24px;
      right: 15px;
      width: 59px;
      height: 50px;
  
    }
  
  }
  
  .schemes--add {

    .scheme_add {
      
      transform: rotateX(0deg);
  
    }
  
    .textarea textarea { min-height: 80px; }
  
  }

}