@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.tariff {

  user-select: none;
  top: -1vw;
  max-width: $apx-885;
  padding: 0 $apx-42 0 $apx-46;

  p { font-family: Roboto !important; }

  button {
  
    background: linear-gradient(0deg, #38874E, #38874E), #3084A8;
    border: $apx-1 solid #46BA5F;
    border-radius: $apx-6;
    padding: $apx-15;
    text-align: center;
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: $apx-20;
    line-height: $apx-26;
    color: #FBFEFD;
    cursor: pointer;
    transition: 0.3s;

    &:hover {

      background: linear-gradient(0deg, #38874E, #38874E), #3084A8;
      border: $apx-1 solid #46BA5F;
      filter: brightness(1.15);
    
    }
  
  }

  &__title {
  
    font-family: Roboto;
    font-weight: 500;
    color: #6CD75B;
    font-size: $apx-40;
    line-height: $apx-46;
    padding: 0;
    margin: $apx-30 0 0;

    &-till {
    
      color: #B3DCFF;
      font-size: $apx-21;
      line-height: $apx-30;
    
    }
  
  }

  &__second_title {

    color: rgba(152, 212, 255, 0.47);
    margin-bottom: $apx-30;

    font-family: Roboto;
    font-size: $apx-21;
    font-weight: 500;
    line-height: normal;

  }

  &__subtitle {
  
    opacity: 0.25;
    font-weight: 500;
    color: #B3DCFF;
    font-size: $apx-24;
    line-height: $apx-28;
    text-align: center;
    position: relative;
    top: -$apx-3;
    white-space: nowrap;

    &_add {
    
      top: -$apx-1;
      left: -$apx-1;
      font-size: $apx-22;
      line-height: $apx-26;
    
    }
  
  }

  &__list__container {
  
    display: flex;
    padding: unset;
    flex-direction: column;

    &__content {
    
      display: flex;
      margin-bottom: -$apx-20;

      svg {
      
        width: $apx-28;
        height: $apx-28;
      
      }

      p {
      
        text-align: center;
        position: relative;
        top: -$apx-19;
        left: $apx-19;
        font-weight: 400;
        font-size: $apx-22;
        line-height: $apx-25;
        color: #B3DCFF;
      
      }
    
    }
  
  }

  &__respect {

    margin-top: 0;
    padding: $apx-10 0;
    border-radius: $apx-21;
    font-size: $apx-20;
    line-height: $apx-23;
    font-weight: 500;
    color: #60B7FF;
    text-shadow: none;
    background-color: #21303E;

    &__icon {

      &--ava {

        width: $apx-50;
        height: $apx-50;
        margin-right: $apx-21;
        border-radius: 100px;
        background-image: url(./icons/ava.png);

      }

      &--smile {

        width: $apx-32;
        height: $apx-31;
        margin: 0 $apx-11;
        background-image: url(./icons/smile.png);

      }

      &--money {

        width: $apx-32;
        height: $apx-31;
        margin-left: $apx-12;
        background-image: url(./icons/money.png);
        
      }

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

    }

  }

  &__buttons__container {
  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    &__content {
    
      display: flex;
      flex-direction: column;
      padding-left: $apx-25;
      padding-right: $apx-25;

      &_left {
        
        width: 46%;
        margin-left: -$apx-23;
      
      }

      &_right {
        
        padding-top: 0.416664vw;
        padding-left: 2.4vw;
        padding-right: unset;

        .tariff__buttons__container__content__subtext {
          span { opacity: 0.75 !important; }
        }
      
      }

      &__text {

        margin-bottom: $apx-8;

        &-left {
      
          font-weight: 500;
          color: #B3DCFF;
          font-size: $apx-26;
          line-height: $apx-32;
        
        }

        &-right {
      
          font-weight: 500;
          color: #F3FF6D;
          font-size: $apx-22;
          line-height: $apx-26;
        
        }

      }

      &__subtext {

        margin: 0 0 $apx-20;
        padding: 0;
        font-size: $apx-20;
        line-height: $apx-23;
        font-weight: 500;
        color: #62B7FF;
        span { opacity: 0.65; }

      }
    
    }
  
  }

}

@include mobile {

  .tariff {

    top: 42px;
    max-width: 94%;
    width: 100%;
    padding: 0 16px;
    border-radius: 14px;
    margin-bottom: 180px;
  
    button {
    
      border: 1px solid #46BA5F;
      border-radius: 6px;
      padding: 15px;
      font-size: 20px;
      line-height: 26px;
    
    }
  
    &__title {
    
      font-size: 24px;
      line-height: 28px;
      margin: 15px 0 0;
  
      &-till {
      
        font-size: 21px;
        line-height: 30px;
      
      }
    
    }
  
    &__second_title {
  
      margin-bottom: 21px;
      font-size: 17px;
  
    }
  
    &__subtitle {
    
      white-space: break-spaces;
      font-size: 16px;
      line-height: 18px;
      top: 0px;
  
      &_add {
      
        top: -1px;
        left: -1px;
        font-size: 22px;
        line-height: 26px;
      
      }
    
    }
  
    &__list__container {

      gap: 15px;
  
      &__content {

        display: flex;
        align-items: center;
        margin-bottom: unset;
  
        svg {
        
          $sizeSvg: 20px;
          min-width: $sizeSvg;
          max-width: $sizeSvg;
          width: $sizeSvg;
          height: $sizeSvg;
        
        }
  
        p {
        
          top: 1px;
          left: 19px;
          padding-right: 22px;
          font-size: 14px;
          line-height: 19px;
          text-align: left;
          margin: 0px;
        
        }
      
      }
    
    }
  
    &__respect {
  
      margin-top: 20px;
      padding: 21px 0 12px;
      border-radius: 21px;
      font-size: 20px;
      line-height: 23px;
      flex-direction: column;
      text-align: center;
  
      &__icon {
  
        &--ava {
  
          width: 70px;
          height: 70px;
          margin-right: unset;
          margin-bottom: 16px;
          border-radius: 100px;
  
        }
  
        &--smile {
  
          display: none;
          // width: 32px;
          // height: 31px;
          // margin: 0 unset;
  
        }
  
        &--money {
  
          width: 52px;
          height: 51px;
          margin-top: 16px;
          margin-left: unset;
          
        }
  
  
      }
  
    }
  
    &__buttons__container {

      flex-direction: column;
      
      &__content {
      
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
  
        &_left {
          
          width: 100%;
          margin-left: 0;
        
        }
  
        &_right {
          
          width: 100%;
          padding-top: 0;
          padding-left: 0;
  
          .tariff__buttons__container__content__subtext {
            margin-top: 0;
          }
        
        }
  
        &__text {
  
          margin-bottom: 8px;
  
          &-left {
        
            font-size: 20px;
            line-height: 32px;
          
          }
  
          &-right {
        
            font-size: 17px;
            line-height: 26px;
          
          }
  
        }
  
        &__subtext {
  
          margin: 0 0 20px;
          font-size: 17px;
          line-height: 23px;
  
        }
      
      }
    
    }
  
  }

}