@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.button_big_add {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $apx-22;
  color: #B2DCFF;
  user-select: none;
  transition: 0.3s;
  opacity: 0.5;
  cursor: pointer;

  &__text { margin-right: $apx-22; }

  svg { 
    $size: $apx-34;
    width: $size;
    height: $size;
    transform: rotate(0deg) scale(1.3);
    transition: 0.3s;
  }

  &:hover { 

    transform: scale( 1.03 ); 
    opacity: 1;
    
  }

}

@include mobile {

  .button_big_add {

    font-size: 15px;
  
    &__text { margin-right: 12px; }
  
    svg { 

      $size: 24px;
      width: $size;
      height: $size;

    }
  
  }

}