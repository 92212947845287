@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.table {

  position: absolute;
  top: $apx-70;
  left: $apx-70;
  min-width: 299px;
  perspective: $apx-700;
  /*z-index: 1;*/
  opacity: 1;
  transition: 0.3s;

  &:hover {

    z-index: 1500;
    
  }

  border-radius: $apx-27;
  background: #243442;
  box-shadow: $apx-0 $apx-1 $apx-23 $apx-3 #00000026;

  &__title, 
  .table__title input[type="text"] {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 100%;
    height: 55px;
    font-family: Roboto Mono;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: $apx-23 $apx-23 $apx-0 $apx-0; 

    input {

      margin-right: $apx-8 !important;
      position: relative;
      top: $apx-0;
      background: unset;
      text-align: center;
      border: unset;

      &:hover, &:focus { outline: none; }

    }

  }

  &__title {

    position: relative;
    text-align: center;
    padding: 0 65px 5px 65px;
    cursor: pointer;
    perspective: $apx-700;
    z-index: 2;

    .dragTable, .saveNameAndColor {
      
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

    }

    .dragTable {

      left: $apx-23;
      cursor: move;
      z-index: 3;

    }

    .saveNameAndColor {

      top: -1px;
      width: 46px;
      height: 46px;
      right: 18px;
      border: $apx-6 solid #0000000d;
      background: #0000008c;
      border-radius: $apx-100;
      z-index: 3;

    }    

    .removeTable {

      opacity: 0;
      transition: 0.3s;
      position: absolute;
      top: $apx-7;
      width: 41px;
      height: 41px;
      padding: 9px;
      right: 9px;
      // border: $apx-0 solid #0000000d;
      background: #0000008c;
      border-radius: $apx-100;
      z-index: 2;
      
    }

    &:hover { .removeTable { opacity: 1 } }

  }

  &__tip {

    position: absolute;
    font-size: 1.4vw;
    color: #3c769d;
    transition: 0.3s;

  }

  &__debug {

    position: absolute;
    top: -2.4vw;
    font-size: 1.4vw;
    color: #3c769d;

  }

  .colorPlaceholder {

    width: 100%;

    .react-colorful { width: 100%; height: 100px !important; }
    
  }

  &__add {

    opacity: 0;
    transition: 0.2s;
    position: absolute;
    z-index: 3;
    bottom: -$apx-28;
    left: 0;
    right: 0;
    margin: auto;
    width: $apx-57;
    height: $apx-57;
    border-radius: $apx-100;
    background-color: #243442;
    // background-image: url(./icons/add.svg);
    // background-size: $apx-37;
    // background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &--light { background-color: #2A3A49; }

  }

  .anchorIcon { transition: 0.3s; }

  &--form-is-opened &__add,
  &:hover &__add { opacity: 1; transition: 0s; }

  &:not(.table--form-is-opened).react-draggable-dragging {

    border: $apx-4 solid #00bcd4;
    .table__add { opacity: 0; }

  }

  &--form-is-opened {

    .anchorIcon { 
      
      path {fill: #F07B73;}
      transform: rotate(45deg);
    
    }

  }

}

.table__row {

  position: relative;
  z-index: 2;
  border-bottom: 1px solid #17222b;
  
  display: grid;
  grid-template-columns: 1fr 160px;

  align-items: center;
  background: #2A3A49;
  user-select: none;
  cursor: pointer;

  &:nth-child( 2n ) { background: #243442; }  

  &:first-child { padding-top: 3px; }

  &:last-child { 

    padding-bottom: 8px;
    border-radius: $apx-0 $apx-0 $apx-23 $apx-23; 

  }

  &_empty {

    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px !important;
    height: 80px;

    span { opacity: 0.8; }

  }

  .table__col {

    font-size: 20px;
    padding: 10px 0 10px 27px;
    white-space: nowrap;

    &__name {

      position: relative;

      &__icon {

        position: absolute;
        left: 6px;
        top: 1px;
        bottom: 0;
        margin: auto;
        transition: 0.3s;

        &.primary {
          
          top: 0;
          path { stroke: #CEBF38; }
        
        }

      }

    }

    &__type { 
      
      overflow: hidden;
      text-overflow: ellipsis;
      color: #617B92; 
    
    }

  }

  &__btn {
    
    position: absolute;
    z-index: 1500;
    opacity: 0;
    transition: 0.3s;
    
    &-move {

      left: -0.4%;
      top: 0;
      bottom: 0.05%;
      margin: auto;
      cursor: n-resize;

    }

    &-remove {

      right: $apx-20;

    }

  }
  
  &:hover { 

    background: #274f5e;
    .table__row__btn { opacity: 1; }   


    .table__col__name__icon { 
      
      left: -30px; 

    }

  }

  &--flying {

    z-index: 1500;
    border: $apx-4 solid #00bcd4;
    border-radius: $apx-2 !important;
    .table__row__move { opacity: 1; }
  }

}

.table__form {

  $form-horizontal-padding: $apx-18;
  position: absolute;
  width: $apx-750;
  padding: $apx-20 $form-horizontal-padding $apx-12;
  border-radius: $apx-27;
  background: #243442;
  box-shadow: $apx-0 $apx-20 $apx-23 -$apx-5 #00000026;

  transform-origin: top;
  transition: 0.4s;
  transform: rotateX(-77deg) translateY($apx-16);
  opacity: 0;

  .textfield__title,
  .textarea__title,
  .selectfield__title 
  { font-size: $apx-20 !important; }

  .two-inputs {

    .selectfield {

      & + .selectfield { margin-left: $apx-16; }

    }

  }

  &__liner_x {

    width: calc( 100% + ($form-horizontal-padding * 2) );
    margin-left: -$form-horizontal-padding;
    height: $apx-2;
    background-color: #2C3E4F;

  }

  &__cutout {

    position: absolute;
    z-index: 1;
    top: -$apx-50;
    left: 0;
    right: 0;
    margin: auto;
    width: $apx-72;
    height: $apx-72;
    border-radius: $apx-100;
    background-color: #1e2c39;

  }

  &__content {

    z-index: 2000;
    position: relative;

  }

  &__col {

    width: 100%;
    max-width: 48.8%;

    &-2 {
      
      .selectfield__title { text-align: right; }

    }

  }

  &__checkboxs {

    padding-right: $apx-10;
    padding-bottom: $apx-1;

    &__col--1 {

      min-width: $apx-138;

    }

  }

  .textarea {

    width: 100%;
    max-width: unset;

    textarea {

      width: 100%;
      max-width: unset;

      padding: 0.156249vw 0.556249vw !important;

    }

  }

  .textfield__title,
  .selectfield__title,
  .textarea__title {

    font-size: 18px;
    font-weight: 400;

  }

}

.table--form-is-opened .table__form {
  
  opacity: 1;
  transform: rotateX(0deg) translateY($apx-14);

}

.dynamicArrows {   

  div {

    z-index: 1 !important;
    pointer-events: none !important;

    svg:hover {

      z-index: 100 !important;
      g { fill: #ffca00; }
      path:first-child { stroke: #ffca00; }

    }

  }
  
}

.constructor--tables-on-top-layer .dynamicArrows div { z-index: 0 !important; }

.tip_rename {

  flex-direction: row-reverse;
  width: 100%;
  top: $apx-20;
  right: -23.604022vw;
  font-size: $apx-18;
  line-height: 100%;
  color: #2c7779;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0.52;
  pointer-events: none;

  svg path { fill: #2c7779; }

  &__arrow {

    transform: rotate(90deg);
    $size: $apx-25;
    width: $size;
    min-width: $size;
    height: $size;
    top: $apx-3;
    left: -$apx-10;

  }

}

.table__top {

  transform-origin: bottom;
  transition: 0.4s;
  transform: rotateX(77deg) translateY(-$apx-2);
  opacity: 0;

}

.title--top-is-opened .table__top {

  opacity: 1;
  transform: rotateX(0deg) translateY(0);

}

.additionalTools {

  position: absolute;
  left: 18px;
  top: 12px;
  width: 30px;
  height: 30px;
  z-index: 0;
  opacity: 0;
  transition: 0.3s;

  &__icon {

    position: absolute;
    top: -10px;
    left: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 2px solid rgba(61, 77, 92, 0.51);
    border-radius: 100%;
    background-color: rgba(42, 58, 73, 0.45);
    transform: scale(30%);
    transition: 0.3s;
    z-index: 0;

    svg path { transition: 0.3s }

    &--sql svg {

      width: 28px;
      height: 28px;

    }

    &--copy svg {

      width: 32px;
      height: 32px;

    }

    &--eye svg {

      width: 28px;
      height: 32px;

    }

  }

  &--shown {

    left: -70px;
    top: -70px;
    width: 120px;
    height: 140px;
    opacity: 1;
    z-index: 1;

    .additionalTools__icon {

      transform: scale(100%);
      z-index: 1;

      &--sql {

        top: 5px;
        left: 68px;

        &:hover {

          border: 2px solid rgba(83, 132, 177, 0.51);
          background-color: rgba(62, 118, 172, 0.08);

          svg path { fill: #52B0FF }

        }
  
      }
  
      &--copy {
  
        top: 28px;
        left: 10px;

        &:hover {

          border: 2px solid rgba(255, 255, 122, 0.21);
          background-color: rgba(255, 224, 131, 0.04);

          svg path { fill: #C7AD6B }

        }
  
      }
  
      &--eye {
  
        top: 90px;
        left: 5px;

        &:hover {

          border: 2px solid rgba(92, 162, 126, 0.51);
          background-color: rgba(42, 73, 47, 0.16);

          svg path { fill: #71C3A1 }

        }
  
      }

    }

  }

  &--light {

    .additionalTools__icon {

      border: 2px solid #7494AF;
      background-color: #fff;

      &--sql {

        &:hover {

          border: 2px solid #52B0FF;
          background-color: #fff;

          svg path { fill: #52B0FF }

        }
  
      }
  
      &--copy {

        &:hover {

          border: 2px solid #4a6d89;
          background-color: #fff;

          svg path { fill: #4a6d89 }

        }
  
      }
  
      &--eye {
  
        top: 90px;
        left: 5px;

        &:hover {

          border: 2px solid #71C3A1;
          background-color: #fff;

          svg path { fill: #71C3A1 }

        }
  
      }

    }

  }

}

@include mobile {

  .table {

    top: 70px;
    left: 70px;
    border-radius: 27px;
    box-shadow: $apx-0 $apx-1 23px $apx-3 #00000026;
  
    &__title, 
    .table__title input[type="text"] {
  
      height: 55px;
      font-size: 20px;
      border-radius: 23px 23px 0px 0px; 
  
      input { margin-right: 8px !important; }
  
    }
  
    &__title {
  
      padding: 0 65px 5px 65px;
      cursor: pointer;
      perspective: $apx-700;
      z-index: 2;
  
      .dragTable {
  
        left: 16px;
        cursor: move;
        z-index: 3;
  
      }
  
      .saveNameAndColor {
  
        top: -3px;
        width: 41px;
        height: 41px;
        right: 8px;
        border: 6px solid #0000000d;
        border-radius: 100px;
  
      }    
  
      .removeTable {
  
        opacity: 1;
        top: 7px;
        width: 41px;
        height: 41px;
        padding: 9px;
        right: 9px;
        border-radius: 100px;
        
      }
  
    }
  
    &__tip {
  
      font-size: 22px;
  
    }
  
    &__debug {
  
      top: -2.4vw;
      font-size: 18px;
  
    }
  
    &__add {
  
      opacity: 1;
      z-index: 3;
      bottom: -28px;
      width: 57px;
      height: 57px;
      border-radius: 100px;
  
    }
  
    &:not(.table--form-is-opened).react-draggable-dragging {
  
      border: 4px solid #00bcd4;
  
    }
  
  }
  
  .table__row {

    grid-template-columns: 1fr 135px !important;
  
    &:last-child { 
  
      padding-bottom: 8px;
      border-radius: 0 0 23px 23px; 
  
    }
  
    .table__col {
  
      font-size: 20px;
      padding: 10px 0 10px 28px;
  
    }
  
    &__btn {
      
      &-move {
  
        left: -0.4%;
        bottom: 0.05%;
  
      }
  
      &-remove { right: 18px; }
  
    }
    
  
    &--flying {
  
      z-index: 1500;
      border: 4px solid #00bcd4;
      border-radius: 2px !important;

    }
  
  }
  
  .table__form {
  
    $form-horizontal-padding: 18px;
    width: 750px;
    padding: 20px $form-horizontal-padding 12px;
    border-radius: 27px;
    box-shadow: 0px 20px 23px -5px #00000026;
  
    transform: rotateX(-77deg) translateY(16px);
  
    .textfield__title,
    .textarea__title,
    .selectfield__title 
    { font-size: 20px !important; }
  
    .two-inputs {
  
      .selectfield {
  
        & + .selectfield { margin-left: 16px; }
  
      }
  
    }
  
    &__liner_x {
  
      width: calc( 100% + ($form-horizontal-padding * 2) );
      margin-left: -$form-horizontal-padding;
      height: 2px;
  
    }
  
    &__cutout {
  
      top: -39px;
      width: 72px;
      height: 72px;
      border-radius: 100px;
  
    }
  
    &__checkboxs {
  
      padding-right: 10px;
      padding-bottom: 1px;
  
      &__col--1 {
  
        min-width: 138px;
  
      }
  
    }
  
    .textarea {
  
      width: 100%;
      max-width: unset;
  
      textarea {
  
        width: 100%;
        max-width: unset;
  
        padding: 8px 10px !important;
  
      }
  
    }
  
    .textfield__title,
    .selectfield__title,
    .textarea__title {
  
      font-size: 18px;
  
    }
  
  }
  
  .tip_rename {
  
    top: 3px;
    right: -305px;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    opacity: 0.9;
  
  
    &__arrow {
  
      $size: 25px;
      width: $size;
      min-width: $size;
      height: $size;
      top: 1px;
      left: -13px;
  
    }
  
  }

  .additionalTools {

    left: -70px;
    top: -70px;
    width: 120px;
    height: 140px;
    opacity: 1;
    z-index: 1;

    .additionalTools__icon {

      transform: scale(100%);
      z-index: 1;

      &--sql {

        top: 5px;
        left: 68px;

        &:hover {

          border: 2px solid rgba(83, 132, 177, 0.51);
          background-color: rgba(62, 118, 172, 0.08);

          svg path { fill: #52B0FF }

        }
  
      }
  
      &--copy {
  
        top: 28px;
        left: 10px;

        &:hover {

          border: 2px solid rgba(255, 255, 122, 0.21);
          background-color: rgba(255, 224, 131, 0.04);

          svg path { fill: #C7AD6B }

        }
  
      }
  
      &--eye {
  
        top: 90px;
        left: 5px;

        &:hover {

          border: 2px solid rgba(92, 162, 126, 0.51);
          background-color: rgba(42, 73, 47, 0.16);

          svg path { fill: #71C3A1 }

        }
  
      }

    }

  }

}