@import "../../../../../../styles/_apx.scss";
@import "../../../../../../styles/_mixins.scss";

.container {

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $apx-17;
  cursor: pointer;

  .new, .color {

    min-width: $apx-30;
    width: $apx-30;
    height: $apx-30;

  }

  .color { border-radius: $apx-4; }

  .name { 

    margin: 0;
    font-weight: 400;
    font-size: $apx-18;
    line-height: $apx-21;
    color: #8CB4D7;
    transition: 0.3s;
    text-align: left;
    transition: 0.4s;

  }

  &:not(.container-choosen):hover .name { color: #B2DCFF; }

  .pen {

    position: relative;
    transition: 0.4s;
    min-width: $apx-24;
    width: $apx-24;
    height: $apx-24;
    margin-left: auto;
    opacity: 0.5;
    
    path { 
      
      transition: 0.4s;
      stroke: rgb(140, 180, 215); 
    
    }

  }


  &:hover {

    .pen {

      opacity: 1;
      right: 0;
      transform: scale(1.25);

    }

  }
  

}

.containerLight {

  .name { color: #3e5767 }

  &:not(.container-choosen):hover .name { color: #19242b; }

  .pen path { stroke: #3e5767 }

}

@include mobile {

  .container {

    gap: 14px;
  
    .new, .color {
  
      min-width: 30px;
      width: 30px;
      height: 30px;
  
    }
  
    .color { border-radius: 4px; }
  
    .name { 
  
      font-size: 15.5px;
      line-height: 21px;
  
    }

    .pen {
  
      min-width: 24px;
      width: 24px;
      height: 24px;
      margin-left: auto;
      
      opacity: 1;
      right: 0;
  
    }    
  
  }

}