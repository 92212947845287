@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

header {

  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: $apx-56;
  padding-left: $apx-15; 
  padding-right: $apx-20; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1a2936e8;
  border-bottom: $apx-1 solid #2C3C4B;
  width: 100%;
  transition: 0.3s;
  
  &:hover { background: #1A2936; }

  .header {

    &__aside {

      min-width: 23%;
      height: 100%;
      align-items: center;
      display: flex;

    }

    &__right { justify-content: flex-end; }

  }

  .logo {

    position: relative;
    width: 100%;
    max-width: $apx-200;
    min-height: 55%;
    max-height: 55%;
    margin-right: $apx-14; 
    border-right: $apx-2 solid rgba(47, 188, 180, 0.11);

  }

  .back-to-schemes {

    position: absolute;
    left: $apx-346;
    cursor: pointer;
    user-select: none;
    font-size: $apx-18;
    color: #2FBCB4;
    padding: 0 $apx-25;
    border-left: $apx-2 solid rgba(47, 188, 180, 0.11);
    border-right: $apx-2 solid rgba(47, 188, 180, 0.11);

    svg { 

      position: relative; 
      top: -$apx-2; 
      margin-right: $apx-12;

      $sizeSvg: $apx-26;
      width: $sizeSvg;
      height: $sizeSvg;

    }

  }

  .stats, .stats__item, .user_container{ display: flex; }

  .stats__item{ align-items: center;}

  .text{

    color: #A6BDD5;
    font-size: $apx-18;
    font-weight: 400;
  }

  .slash{

    border: $apx-1 solid #2C3C4B;
    width: $apx-28;
    margin-right: $apx-15;
    margin-left: $apx-15;
    transform: rotate( 90deg);
      
  }

  .user_container { 
    
    cursor: pointer; 
    user-select: none; 

    input {

      width: $apx-1;
      min-width: $apx-17;
      font-family: Roboto Mono;
      color: #2FBCB4;
      font-size: $apx-18;
      background: unset;
      border: unset;

      &:hover, &:focus { outline: none; }

    }
  
  }

  .user_name{

    font-family: Roboto Mono;
    color: #2FBCB4;
    font-size: $apx-18;

  }

  .number{ 
    
    font-weight: 500;
    margin-left: $apx-14;
    font-size: $apx-18;
  
  }

  .arrow{
    
    width: $apx-13;
    margin-left: $apx-17;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .zoom_btns {

    padding: 0 $apx-12;
    margin-right: $apx-20;  
    margin-left: 0.44vw;
    border-left: $apx-2 solid rgba(47, 188, 180, 0.11);
    border-right: $apx-2 solid rgba(47, 188, 180, 0.11);
  
    svg {
  
      cursor: pointer;
      width: $apx-26;
      height: $apx-26;
      transition: 0.3s;
  
      & + svg { margin-left: $apx-10; }
  
      &:hover { transform: scale(1.23); }
  
    }
    
  }

}

@include mobile {

  header {

    height: 40px;
    padding-left: 6px; 
    padding-right: 12px;
    border-bottom: 1px solid #2C3C4B;
    background: #1A2936;

    .header {

      &__aside { min-width: unset; }
      &__middle { display: none; }

    }
  
    .logo {
  
      display: none;
      // max-width: 120px;
      // margin-right: 14px; 
      // border-right: 2px solid rgba(47, 188, 180, 0.11);
  
    }
  
    .back-to-schemes {
  
      left: 118px;
      padding: 0 10px;
      border-left: 2px solid rgba(47, 188, 180, 0.11);
      border-right: 2px solid rgba(47, 188, 180, 0.11);
  
      svg { 
  
        top: -1px; 
        margin-right: 2px;
        $sizeSvg: 21px;
        width: $sizeSvg;
        height: $sizeSvg;
  
      }

      span { display: none; }
  
    }
  
    .text{ font-size: 18px; }
  
    .slash{
  
      border: 1px solid #2C3C4B;
      width: 28px;
      margin-right: 15px;
      margin-left: 15px;
        
    }
  
    .user_container { 
  
      input {
  
        width: 1px;
        min-width: 17px;
        font-size: 18px;
  
      }
    
    }
  
    .user_name { 
      
      font-size: 14px; 
      line-height: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 101px;

    }
  
    .number{ 
      
      margin-left: 14px;
      font-size: 18px;
    
    }
  
    .arrow{
      
      width: 11px;
      margin-left: 9px;
      
    }

    .zoom_btns {

      display: none;

      padding: 0 10px;
      margin-right: 12px;  
      margin-left: 0.44vw;
      border-left: 2px solid rgba(47, 188, 180, 0.11);
      border-right: 2px solid rgba(47, 188, 180, 0.11);
    
      svg {
    
        position: relative;
        top: -1px;
        $helpSize: 22px;
        width: $helpSize;
        height: $helpSize;
    
        & + svg { margin-left: 10px; }
    
        &:hover { transform: scale(1.23); }
    
      }
      
    }

    .options {

      transform-origin: top right;

    }
  
  }

}