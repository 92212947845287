@import "../../../styles/_apx.scss";
@import "../../../styles/_mixins.scss";

.radio {

  &__element {

    font-size: $apx-22;
    line-height: 100%;
    font-family: 'Roboto Mono';
    color: #8AB1D3;

    & + .radio__element { margin-top: $apx-20; }

    &__anchor {

      top: $apx-1;
      margin-right: $apx-18;

      $radio_size: $apx-28;
      width: $radio_size;
      height: $radio_size;

      background: #283846;
      border: $apx-2 solid #3C566C;
      border-radius: 100px;

      display: grid;
      place-content: center;

      div {

        $dot_size: $apx-14;
        width: $dot_size;
        height: $dot_size;
        transform: scale(0);
        transition: 0.3s;
        border: $apx-2 solid transparent;
        border-radius: 100px;

      }

    }

    &--selected {

      color: #9AC3E7;
      font-weight: 600;
      
      .radio__element__anchor {

        div { 
          
          background: #CEBF38;
          border-color: #CE9238;
          transform: scale(1);
        
        }

      }

    }

  }

}

@include mobile {

  .radio {

    &__element {
  
      font-size: 22px;
  
      & + .radio__element { margin-top: 20px; }
  
      &__anchor {
  
        top: 1px;
        margin-right: 18px;
  
        $radio_size: 28px;
        width: $radio_size;
        height: $radio_size;
  
        border: 2px solid #3C566C;
  
        div {
  
          $dot_size: 14px;
          width: $dot_size;
          height: $dot_size;
          border: 2px solid transparent;
  
        }
  
      }

    }
  
  }

}