@import "../../../../../../styles/_apx.scss";
@import "../../../../../../styles/_mixins.scss";

.container {

  position: relative;
  width: 100%;
  padding: $apx-19 $apx-20 $apx-30;
  border-radius: $apx-14;
  display: flex;
  flex-direction: column;
  background-color: #243442;
  box-shadow: 0 0.052083vw 1.197909vw 0.156249vw rgba(0, 0, 0, 0.1490196078);
  transition: 0.4s;

  .title {

    margin-bottom: $apx-35;
    font-weight: 400;
    font-size: $apx-22;
    line-height: $apx-26;
    letter-spacing: 0.015vw;
    color: #B2DCFF;
    text-align: center;
    transition: 0.4s;

  }

  .line {

    position: absolute;
    left: 0;
    top: $apx-63;
    width: 100%;
    height: $apx-1;
    background-color: #324351;
    transition: 0.4s;

  }

  .search {

    input {

      height: unset !important;
      margin: 0 !important;
      padding: $apx-11 $apx-17 $apx-11 $apx-49;
      border: $apx-1 solid #43586B;
      border-radius: $apx-8 !important;
      font-weight: 400 !important;
      font-size: $apx-17 !important;
      line-height: $apx-20;
      text-align: left;
  
      ::placeholder {
  
        font-weight: 400 !important;
        font-size: $apx-17 !important;
        line-height: $apx-20;
  
      }

    }

    svg {

      width: $apx-30;
      height: $apx-30;
      top: $apx-4;
      left: $apx-9;
      right: unset;

    }

  }

  .rows {

    display: flex;
    flex-direction: column;
    gap: $apx-9;
    max-height: $apx-188;
    padding-right: $apx-10;
    overflow-y: auto;

    &::-webkit-scrollbar {

      width: $apx-5;
      border-radius: $apx-3;
      background-color: #293D4E;

    }
    
    &::-webkit-scrollbar-thumb {

      width: $apx-5;
      border-radius: $apx-3;
      background-color: #527DA2;

    }

  }

}

.containerLight {

  background-color: #fff;
  box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

  .title { color: #3e5767 }

  .line { background-color: #a8a8a8 }

  .rows {

    &::-webkit-scrollbar {

      background-color: transparent;

    }

  }

}

@include mobile {

  .container {
    
    padding: 19px 16px 16px;
    border-radius: 14px;
  
    .title {
  
      margin-bottom: 35px;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.015vw;
  
    }
  
    .line {
  
      top: 63px;
      height: 1px;
  
    }
  
    .search {
  
      input {
        
        padding: 11px 17px 11px 49px;
        border: 1px solid #43586B;
        border-radius: 8px !important;
        font-size: 17px !important;
        line-height: 20px;
    
        ::placeholder {
    
          font-size: 17px !important;
          line-height: 20px;
    
        }
  
      }
  
      svg {
  
        width: 30px;
        height: 30px;
        top: 4px;
        left: 9px;
  
      }
  
    }
  
    .rows {
      
      gap: 18px;
      max-height: 188px;
      padding-right: 10px;
  
      &::-webkit-scrollbar {
  
        width: 5px;
        border-radius: 3px;
  
      }
      
      &::-webkit-scrollbar-thumb {
  
        width: 5px;
        border-radius: 3px;
  
      }
  
    }
  
  }

}